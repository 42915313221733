import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2 {...{
      "id": "usememo-is-your-new-best-dataviz-friend"
    }}>{`useMemo is your new best dataviz friend`}</h2>
    <p>{`My favorite hook for making React and D3 work together is `}<inlineCode parentName="p">{`useMemo`}</inlineCode>{`. It's like
a combination of `}<inlineCode parentName="p">{`useEffect`}</inlineCode>{` and `}<inlineCode parentName="p">{`useState`}</inlineCode>{`.`}</p>
    <p>{`Remember how the rest of this course focused on syncing D3's internal state
with React's internal state and complications around large datasets and
speeding up your D3 code to avoid recomputing on every render?`}</p>
    <p>{`All that goes away with `}<inlineCode parentName="p">{`useMemo`}</inlineCode>{` – it memoizes values returned from a function
and recomputes them when particular props change. Think of it like a cache.`}</p>
    <p>{`Say you have a D3 linear scale. You want to update its range when your
component's width changes.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`function MyComponent({ data, width }) {
    const scale = useMemo(() =>
        d3.scaleLinear()
            .domain([0, 1])
            .range([0, width])
    ), [width])

    return <g> ... </g>
}
`}</code></pre>
    <p><inlineCode parentName="p">{`useMemo`}</inlineCode>{` takes a function that returns a value to be memoized. In this case
that's the linear scale.`}</p>
    <p>{`You create the scale same way you always would. Initiate a scale, set the
domain, update the range. No fancypants trickery.`}</p>
    <p><inlineCode parentName="p">{`useMemo`}</inlineCode>{`'s second argument works much like useEffect's does: It tells React
which values to observe for change. When that value changes, `}<inlineCode parentName="p">{`useMemo`}</inlineCode>{` reruns
your function and gets a new scale.`}</p>
    <p>{`Don't rely on `}<inlineCode parentName="p">{`useMemo`}</inlineCode>{` running only once however. Memoization is meant as a
performance optimization, a hint if you will, not as a syntactical guarantee of
uniqueness.`}</p>
    <p>{`And that's exactly what we want. No more futzing around with
`}<inlineCode parentName="p">{`getDerivedStateFromProps`}</inlineCode>{` and `}<inlineCode parentName="p">{`this.state`}</inlineCode>{`. Just `}<inlineCode parentName="p">{`useMemo`}</inlineCode>{` and leave the rest
to React. ✌️`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      